import "./App.css";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";

import Home from "./components/Home";
import Team from "./components/Team";
import History from "./components/History";
import Progress from "./components/progress/Progress";
import Gallery from "./components/Gallery";
import Cooperation from "./components/Cooperation";
import Reports from "./components/Reports";
import Contact from "./components/contact/Contact";
import News from "./components/news/News";
import ArticleSwitcher from "./components/news/ArticleSwticher";

import plFlag from "./assets/pl_flag.png";
import gbFlag from "./assets/gb_flag.png";

function App() {
  SwiperCore.use([Autoplay, Pagination]);

  const { t, i18n } = useTranslation("common");

  const changeLanguageToPl = () => i18n.changeLanguage("pl");
  const changeLanguageToEn = () => i18n.changeLanguage("en");

  return (
    <div className="App">
      <Router>
        <Navbar bg="dark" expand="lg" fixed="top" variant="dark">
          <Container>
            <Navbar.Brand href="https://www.facebook.com/FundacjaPolskieSamolotyHistoryczne/">
              <FontAwesomeIcon icon={faFacebookSquare} inverse size="2x" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-between"
            >
              <Nav className="me-auto ml-auto mr-auto">
                <LinkContainer to="/">
                  <Nav.Link active={false}>{t("menu.home")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/team">
                  <Nav.Link active={false}>{t("menu.team")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/news">
                  <Nav.Link active={false}>{t("menu.news")}</Nav.Link>
                </LinkContainer>
                <NavDropdown title="PZL P.11" id="basic-nav-dropdown">
                  <LinkContainer to="/history">
                    <NavDropdown.Item>{t("menu.history")}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/progress">
                    <NavDropdown.Item>{t("menu.progress")}</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/gallery">
                  <Nav.Link active={false}>{t("menu.gallery")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cooperation">
                  <Nav.Link active={false}>{t("menu.cooperation")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/reports">
                  <Nav.Link active={false}>{t("menu.reports")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact">
                  <Nav.Link active={false}>{t("menu.contact")}</Nav.Link>
                </LinkContainer>
              </Nav>
              <Nav>
                <Nav.Link onClick={changeLanguageToPl}>
                  <img src={plFlag} alt="PL" />
                </Nav.Link>
                <Nav.Link onClick={changeLanguageToEn}>
                  <img src={gbFlag} alt="EN" />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Swiper
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
        >
          {[4, 1, 6, 2, 0, 5, 3].map((n) => (
            <SwiperSlide key={n}>
              <img
                src={require(`./assets/carousel/carousel-00${n}.jpg`).default}
                alt="Slide"
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>

        <Switch>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/news">
            <News />
          </Route>
          <Route path="/news/:slug">
            <ArticleSwitcher />
          </Route>
          <Route exact path="/history">
            <History />
          </Route>
          <Route exact path="/progress">
            <Progress />
          </Route>
          <Route exact path="/gallery">
            <Gallery />
          </Route>
          <Route exact path="/cooperation">
            <Cooperation />
          </Route>
          <Route exact path="/reports">
            <Reports />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>

        <footer className="mt-3 py-5 bg-dark">
          <Container>
            <p className="m-0 text-center text-white">
              Copyright © Fundacja Polskie Samoloty Historyczne 2021
            </p>
          </Container>
        </footer>
      </Router>
    </div>
  );
}

export default App;
