import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

function Reports() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("reports.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("reports.header")}</Breadcrumb.Item>
      </Breadcrumb>

      <ul className="fa-ul">
        <li>
          <FontAwesomeIcon icon={faFilePdf} size="lg" listItem />
          <a
            target="_blank"
            rel="noreferrer"
            href={
              process.env.PUBLIC_URL +
              "/reports/Sprawozdanie-z-dzialalnosci-fundacji_2017.pdf"
            }
          >
            {t("reports.title")} 2017
          </a>
        </li>
        <li>
          <FontAwesomeIcon icon={faFilePdf} size="lg" listItem />
          <a
            target="_blank"
            rel="noreferrer"
            href={
              process.env.PUBLIC_URL +
              "/reports/Sprawozdanie-z-dzialalnosci-fundacji_2018.pdf"
            }
          >
            {t("reports.title")} 2018
          </a>
        </li>
      </ul>
    </Container>
  );
}

export default Reports;
