import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image, Table } from "react-bootstrap";

const CenteredImageWithCaption = styled.div`
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 3vh;
  & > div {
    color: #868e96;
  }
`;

const CenteredTableWithCaption = styled.div`
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 3vh;
  & > table > caption {
    text-align: center;
  }
`;

function AnalizaSilOporu() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">
        ANALIZA SIŁ OPORU AERODYNAMICZNEGO POSZCZEGÓLNYCH PODZESPOŁÓW SAMOLOTU
        PZL P.11c
      </h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          ANALIZA SIŁ OPORU AERODYNAMICZNEGO POSZCZEGÓLNYCH PODZESPOŁÓW SAMOLOTU
          PZL P.11c
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>2020-06-13</div>
      <hr />
      <div className="mb-3">
        <div>
          Pierwsza połowa XX wieku to okres bardzo dynamicznego rozwoju
          lotnictwa. Patrząc na ewolucję samolotów z tego okresu bardzo łatwo
          zauważyć pewne charakterystyczne etapy zmiany w ich sylwetkach. Od
          prymitywnych (z dzisiejszego punktu widzenia) konstrukcji braci Wright
          po opływowe sylwetki Spitfire’ów i Messerschmitt’ów z okresu II wojny
          światowej. W powszechnej świadomości istnieje przekonanie, że zmiany
          te były zdeterminowane uzyskaniem lepszej „aerodynamiki” samolotu,
          jednak jak duży wpływ na osiągi miały konkretne udoskonalenia? W
          poniższym artykule postaram się przybliżyć odpowiedź na to pytanie.
          Jako przykład posłużą mi komputerowe obliczenia CFD samolotu PZL
          P.11c.
        </div>
        <div>
          W celu uświadomienia sobie wymiernej korzyści płynącej z wprowadzenia
          konkretnego ulepszenia w geometrii samolotu należy zrozumieć naturę
          sił aerodynamicznych działających na samolot [1]. Na wypadkową siłę
          aerodynamiczną P­A składają się: siła nośna PZ oraz siła oporu Px.
          Obie siły zależą od: gęstości, kwadratu prędkości lotu, powierzchni
          nośnej oraz współczynników wynikających z geometrii samolotu i kąta
          natarcia. Współczynnik siły nośnej CZ zależy w głównej mierze od
          kształtu skrzydła i jego profilu (przekroju poprzecznego), natomiast
          współczynnik siły oporu CX zależy od „opływowości” sylwetki całego
          samolotu. Korzyścią płynącą z jak najmniejszej wartości CX jest to, że
          samolot może lecieć szybciej (dla myśliwców) lub do lotu z daną
          prędkością potrzebuje mniej siły a więc i paliwa (dla samolotów
          komunikacyjnych).
        </div>
        <CenteredImageWithCaption>
          <Image
            src={
              require(`../../../assets/news/analiza-sil-oporu-aerodynamicznego-poszczegolnych-podzespolow-samolotu-pzl-p-11c-article-1.jpg`)
                .default
            }
            fluid
          />
          <div>Rysunek 1 Składowe siły aerodynamicznej</div>
        </CenteredImageWithCaption>
        <div>
          W tabeli 1 pokazano składowe siły oporu, jaki procent całego oporu
          samolotu generuje konkretny podzespół. W tabeli 2 pokazano wartość
          teoretycznej prędkości o jaką mógłby szybciej lecieć samolot gdyby nie
          opór poszczególnego podzespołu. Należy oczywiście zaznaczyć tu, że
          podana analiza ma charakter wyłącznie poglądowy gdyż nie można
          rozpatrywać właściwości aerodynamicznych całego samolotu jako sumy
          właściwości poszczególnych podzespołów. Niemniej daje ona ogólny obraz
          problemu.
        </div>
        <CenteredTableWithCaption>
          <Table>
            <caption>
              <p>Tabela 1 Składowe siły oporu</p>
            </caption>
            <thead>
              <tr>
                <td>Podzespół</td>
                <td>
                  C<small>xi</small>
                </td>
                <td>
                  C<small>xi</small>/ C<small>xC</small> [%]
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kadłub z silnikiem</td>
                <td>0.017</td>
                <td>37.9</td>
              </tr>
              <tr>
                <td>Płat</td>
                <td>0.014</td>
                <td>32.0</td>
              </tr>
              <tr>
                <td>Stałe podwozie</td>
                <td>0.005</td>
                <td>12.2</td>
              </tr>
              <tr>
                <td>Zastrzały</td>
                <td>0.005</td>
                <td>10.9</td>
              </tr>
              <tr>
                <td>Usterzenie</td>
                <td>0.002</td>
                <td>3.6</td>
              </tr>
              <tr>
                <td>Usterzenie</td>
                <td>0.001</td>
                <td>3.4</td>
              </tr>
              <tr>
                <td>Opór całkowity</td>
                <td>0.044</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </CenteredTableWithCaption>
        <CenteredTableWithCaption>
          <Table>
            <caption>
              <p>
                Tabela 2 Szacunkowe wartości przyrostu prędkości maksymalnej
                samolotu{" "}
              </p>
            </caption>
            <thead>
              <tr>
                <td>Podzespół</td>
                <td>
                  V<small>dodatkowe</small> [km/h]
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Stałe podwozie</td>
                <td>25.2</td>
              </tr>
              <tr>
                <td>Zastrzały</td>
                <td>22.2</td>
              </tr>
              <tr>
                <td>Otwarta Kabina z Pilotem</td>
                <td>6.5</td>
              </tr>
              <tr>
                <td>Suma</td>
                <td>54.0</td>
              </tr>
            </tbody>
          </Table>
        </CenteredTableWithCaption>

        <div>
          Pierwszymi elementami na które należy zwrócić uwagę jest stałe
          podwozie oraz zastrzały skrzydeł i usterzenia. Warto zaznaczyć, że w
          P.11 zastosowano polski patent tzw. podwozie nożycowe [2], w którym
          amortyzatory schowane były w obrysie kadłuba nie generując oporu. Nie
          zmienia to faktu, że w późniejszych konstrukcjach stosowano podwozie
          chowane oraz skrzydła samonośne o znacznie lepszych właściwościach
          aerodynamicznych. Kolejny element jaki zwraca na siebie uwagę to
          otwarta kabina pilota. O ile opór powstały przy opływie podwozia i
          zastrzałów wynika z dodatkowej powierzchni, które musi opłynąć
          powietrze o tyle w przypadku kabiny powstaje on na skutek powstających
          zawirowań (tzw. oderwanie przepływu, więcej w [3]). Z problemem tym
          poradzono sobie już modelu P.24 z tej samej serii, stosując osłoniętą
          kabinę, będącą standardem w kolejnych generacjach samolotów.{" "}
        </div>
        <CenteredImageWithCaption>
          <Image
            src={
              require(`../../../assets/news/analiza-sil-oporu-aerodynamicznego-poszczegolnych-podzespolow-samolotu-pzl-p-11c-article-2.jpg`)
                .default
            }
            fluid
          />
          <div>
            Rysunek 2 Linie prądu opływu wokół podwozia i zastrzałów skrzydła
          </div>
        </CenteredImageWithCaption>

        <CenteredImageWithCaption>
          <Image
            src={
              require(`../../../assets/news/analiza-sil-oporu-aerodynamicznego-poszczegolnych-podzespolow-samolotu-pzl-p-11c-article-3.jpg`)
                .default
            }
            fluid
          />
          <div>Rysunek 3 Linie prądu opływu wokół kabiny pilota</div>
        </CenteredImageWithCaption>

        <div>
          W efekcie rozwiązania wymienionych problemów można założyć, że udałoby
          się uzyskać dodatkowe 50 km/h maksymalnej prędkości (tabela 2), co
          przy około 375 km/h maksymalnej prędkości P.11 daje 425 km/h. Dla
          porównania prędkości maksymalne Messerschmitt’a Bf 109 (w zależności
          od wersji) to około 500 km/h. Różnica wynika z zupełnie innych założeń
          konstrukcyjnych obu samolotów, a w konsekwencji i całych sylwetek.
          Zastrzałowy górnopłat został zastąpiony wolnonośnym dolnopłatem,
          zamiast silnika gwiazdowego o dużej powierzchni czołowej użyto silnika
          rzędowego o większej mocy. Pokazuje to jak dynamiczny był rozwój
          lotnictwa w tamtym okresie oraz, że udoskonalanie konstrukcji pod
          względem aerodynamicznym, czy szerzej przepływowym, niesie ze sobą
          bardzo wymierne korzyści a współczesne narzędzia (komputerowe
          obliczenia numeryczne CFD) sprawiają, że są one nieporównywalnie
          bardziej dostępne niż kiedykolwiek wcześniej.
        </div>
        <hr />
        <h3>O AUTORZE</h3>
        <div>
          Mgr inż. Paweł Stalmach jest absolwentem wydziału Mechanicznego
          Energetyki i Lotnictwa Politechniki Warszawskiej kierunków Lotnictwo i
          Kosmonautyka oraz Mechanika i Budowa Maszyn. Obecnie prowadzi własne
          biuro obliczeniowo-konstrukcyjne [www.kufengineering.com]
          specjalizujące się w wykonywaniu obliczeń i analiz inżynierskich, w
          szczególności z wykorzystaniem komputerowych obliczeń numerycznych.
          Artykuł powstał na podstawie pracy magisterskiej autora, zrealizowanej
          przy współpracy z Fundacją Polskie Samoloty Historyczne
          [samolotyhistoryczne.org] zajmującej się budową latającej repliki
          samolotu PZL P11c.
        </div>
        <hr />
        <h3>LITERATURA</h3>
        <div>
          [1] „Konstruowanie samolotów: wyznaczanie obciążeń”, S. Danilecki,
          Wrocław, Oficyna Wydawnicza Politechniki Wrocławskiej, 2004,
          83-7085-833-3
        </div>
        <div>
          [2] „Monografie Lotnicze – P.Z.L. P.11”, A .Glass, T. Kopański, T.
          Makowski, Część I i II, Gdynia, AJ-Press, 1997
        </div>
        <div>
          [3] „Aerodynamics for Engineering Students”, E.L. Houghton P.W.
          Carpenter Steven H. Collicott Daniel T. Valentine, Oficyna Elsevier,
          2013, 978-0-08-096632-8
        </div>
      </div>
    </Container>
  );
}

export default AnalizaSilOporu;
