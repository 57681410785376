import React from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image } from "react-bootstrap";

function EnKolejnaPracaMagisterkaObroniona() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">
        Another master's thesis on P11 defended. Congratulations to Paweł
        Stalmach on his very interesting work!
      </h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          Another master's thesis on P11 defended. Congratulations to Paweł
          Stalmach on his very interesting work!
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>2019-01-25</div>
      <hr />
      <div className="mb-3">
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-1.jpg`)
              .default
          }
          fluid
        />
        <div className="mt-3">
          Animation of a flow over the P.11c basics airfoil (airfoil No.323 of
          Warsaw Aerodynamics Istitiute)
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-2.gif`)
              .default
          }
          fluid
        />
        <div className="mt-3">
          Animation of a flow over the Bartel 37 II a airfoil (supposed airfoil
          of the P.11c wing)
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-3.gif`)
              .default
          }
          fluid
        />
        <div className="mt-3">Wingtip vortices</div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-4.jpg`)
              .default
          }
          fluid
        />
        <div className="mt-3">
          Total pressure contours behind the wing informs about drag that
          appears
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-5.jpg`)
              .default
          }
          fluid
        />
        <div className="mt-3">
          Streamlines over cylinders coloured by pressure coefficient
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-6.jpg`)
              .default
          }
          fluid
        />
        <div className="mt-3">Vortices generated by open cockpit</div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-7.jpg`)
              .default
          }
          fluid
        />
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-8.jpg`)
              .default
          }
          fluid
        />
        <div className="mt-3">Flow over profiled strut</div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-9.jpg`)
              .default
          }
          fluid
        />
        <div className="mt-3">
          Animation of a flow over complete plane with angle of attack close to
          critical, seen strong turbulence over open cockpit
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-10.gif`)
              .default
          }
          fluid
        />
        <div className="mt-3">
          Flow over the wing in section with cut for better visibility, seen
          influence of struts and landing gear
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-11.gif`)
              .default
          }
          fluid
        />
      </div>
    </Container>
  );
}

export default EnKolejnaPracaMagisterkaObroniona;
