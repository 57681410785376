import { Card } from "react-bootstrap";

function TeamCard({ initials, name, jobTitle, degree, description }) {
  return (
    <Card style={{ border: "none" }}>
      <Card.Img
        variant="top"
        src={require(`../assets/team/team_${initials}.jpg`).default}
      />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text as="div">
          <div className="blockquote">
            <div className="blockquote-footer">{jobTitle}</div>
          </div>

          <p>{degree}</p>
          <p>{description}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default TeamCard;
