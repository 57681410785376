import React from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image } from "react-bootstrap";

function PlBadinHistoriaNiezwykla() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">
        Niezwykła historia pozyskania kontrolera lotu BADIN
      </h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          Niezwykła historia pozyskania kontrolera lotu BADIN
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>2021-12-28</div>
      <hr />
      <div className="mb-3">
        W ubiegłym roku nawiązaliśmy kontakt z pasjonatem historycznych
        samolotów Panem Stefanem Wilskim. Pan Stefan jest Belgiem polskiego
        pochodzenia, działa w prywatnym muzeum lotnictwa w Brukseli – BAPA –
        <a href="https://bapa.aero/"> https://bapa.aero/</a>, zajmującym się
        remontowaniem i ekspozycją zabytkowych samolotów. Podczas długich rozmów
        o naszych planach budowy PZL P11c poprosiliśmy o pomoc w pozyskaniu
        kontrolera lotu do naszego samolotu. Po dość długich poszukiwaniach
        udało się znaleźć odpowiedni instrument w prywatnej kolekcji.
        Właścicielem kolekcji okazał się mieszkający w Belgii Pan Didier
        Campion. Tu zaczyna się bardzo ciekawa historia. Kuzyn Pana Campion,
        ppor.Robert Vander Auwera (oryginalne nazwisko) w 1920 roku jako
        ochotnik walczył w eskadrze lotniczej Wojska Polskiego, niestety oprócz
        kilku fotografii niewiele więcej było wiadomo o losach kuzyna.
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-1.jpg`)
            .default
        }
        fluid
        style={{ maxHeight: "500px" }}
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-2.jpg`)
            .default
        }
        fluid
        style={{ maxHeight: "500px" }}
      />
      <div className="mb-3" style={{ color: "#868e96" }}>
        ppor. Robert Vander Auwera - zdjęcia z kolekcji Pana Campion
      </div>
      <div className="mb-3">
        Bardzo nas ta historia zaciekawiła, zaczęliśmy przeszukiwać nasze
        archiwa i rzeczywiście znaleźliśmy sporo informacji dotychczas
        nieznanych rodzinie Pana Campion, oto niektóre z nich:
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-3.jpg`)
            .default
        }
        fluid
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-4.jpg`)
            .default
        }
        fluid
      />
      <div className="mb-3 mt-3">
        Rewelacyjny okazał się fragment wspomnień Wacława Makowskiego,
        wydawnictwo ZP ISBN 978-83-61529-87-3 - „ Cywil w wojsku – wspomnienia z
        życia i wojen cz.I i II” gdzie Wacław Makowski opisuje wspólny lot
        rozpoznawczy z ppor.Robertem Vander Auwera , który to lot nie zakończył
        się dobrze dla młodego Belga, przeczytajcie:
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-5.jpg`)
            .default
        }
        fluid
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-6.jpg`)
            .default
        }
        fluid
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-7.jpg`)
            .default
        }
        fluid
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-8.jpg`)
            .default
        }
        fluid
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-9.jpg`)
            .default
        }
        fluid
      />
      <div className="mb-3 mt-3">
        Ostatecznie kontroler lotów z kolekcji Pana Campion został podarowny
        Fudacji Polskie Samoloty Historyczne i obecnie trwają prace nad
        konserwacją tak aby można go było wykorzystać w naszej PZL P11c.
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-10.jpg`)
            .default
        }
        fluid
      />
      <div className="mb-3 mt-3">Bardzo dziękujemy Panie Campion!</div>
      <div className="mb-3">
        Podziękowania również dla Pana Stefana Wilskiego za wsparcie naszych
        prac i zaangażowanie w pozyskanie i dostarczenie instrumentu.
      </div>
      <div className="mb-3">
        Dziękujemy również naszym Przyjaciołom z Krakowa i Zielonki za pomoc w
        pozyskaniu dokumentów oraz Panu Janowi Hofmanowi z Muzeum Lotnictwa
        Polskiego w Krakowie za niezwykle barwne opowieści o historii lotnictwa
        które oczarowały naszego gościa.
      </div>
      <div className="mb-3">
        Ps. Gdyby ktoś znał jakieś nieznane dotychczas szczegóły dotyczące losów
        ppor. Roberta Vander Auwera prosimy o kontakt z Fundacją Polskie
        Samoloty Historyczne.
      </div>
    </Container>
  );
}

export default PlBadinHistoriaNiezwykla;
