import React from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image } from "react-bootstrap";

function PlKolejnaPracaMagisterkaObroniona() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">
        Kolejna praca magisterska z P11-stki obroniona. Gratulujemy Pawłowi
        Stalmachowi bardzo ciekawej pracy!
      </h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          Kolejna praca magisterska z P11-stki obroniona. Gratulujemy Pawłowi
          Stalmachowi bardzo ciekawej pracy!
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>2019-01-25</div>
      <hr />
      <div className="mb-3">
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-1.jpg`)
              .default
          }
          fluid
        />
        <div>
          Animacja opływu bazowego profilu P.11c (profil nr.323 Instytutu
          Aerodynamicznego w Warszawie)
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-2.gif`)
              .default
          }
          fluid
        />
        <div>
          Animacja opływu profilu Bartel 37 II a (domniemanego profilu P.11c)
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-3.gif`)
              .default
          }
          fluid
        />
        <div>Wiry krawędziowe powstające na końcu płata</div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-4.jpg`)
              .default
          }
          fluid
        />
        <div>
          Kontury ciśnienia całkowitego za płatem, informujące o powstających
          stratach (oporze)
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-5.jpg`)
              .default
          }
          fluid
        />
        <div>
          Linie prądu wokół cylindrów kolorowane wartością współczynnika
          ciśnienia (kolektor i pierścień Townend'a ukryte)
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-6.jpg`)
              .default
          }
          fluid
        />
        <div>Zawirowania wywołane przez kabinę generujące znaczny opór</div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-7.jpg`)
              .default
          }
          fluid
        />
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-8.jpg`)
              .default
          }
          fluid
        />
        <div>Opływ wokół oprofilowanego zastrzału</div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-9.jpg`)
              .default
          }
          fluid
        />
        <div>
          Animacja opływu całego samolotu dla kąta natarcia bliskiego
          krytycznemu, widać silne zaburzenie wywołane otwartą kabiną
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-10.gif`)
              .default
          }
          fluid
        />
        <div>
          Strugi odrywające się od płata w miejscu jego wycięcia oraz zaburzenia
          przepływu wywołane zastrzałami i stałym podwoziem
        </div>
        <Image
          src={
            require(`../../../assets/news/kolejna-praca-magisterska-z-p11-stki-obroniona-article-11.gif`)
              .default
          }
          fluid
        />
      </div>
    </Container>
  );
}

export default PlKolejnaPracaMagisterkaObroniona;
