export const Articles = {
  "skanowanie-pzl-p-11c-w-mlp-krakow": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/skanowanie-pzl-p-11c-w-mlp-krakow-article-1.jpg`)
        .default,
    ],
    pl: {
      title: "Skanowanie PZL P.11c w MLP Kraków",
      summary:
        "Zespół PZL P.11, zeskanował bryłę oraz dostępne elementy płatowca jedynego zachowanego egzemlarza PZL P.11 na świecie. Samolot znajduję się w...",
      text: "Zespół PZL P.11, zeskanował bryłę oraz dostępne elementy płatowca jedynego zachowanego egzemlarza PZL P.11 na świecie. Samolot znajduję się w Muzeum Lotnictwa Polskiego w Krakowie. Pomiary bardzo pomogą w dokończeniu opracowania dokumentacji potrzebnej do wykonania latającej repliki PZL P.11",
    },
    en: {
      title: "Scanning of the PZL P.11 at the Polish Aviation Museum in Cracow",
      summary:
        "The PZL P.11 team has scanned the body and available airframe elements of the only preserved PZL P.11 example in...",
      text: "The PZL P.11 team has scanned the body and available airframe elements of the only preserved PZL P.11 example in the world. The plane is in the Polish Aviation Museum in Cracow. The measurements will help to complete the preparation of the documentation needed to make the flying PZL P.11 replica.",
    },
  },
  "postepy-w-dokumentacji-do-pzl-p-11": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/postepy-w-dokumentacji-do-pzl-p-11-article-1.jpg`)
        .default,
      require(`../assets/news/postepy-w-dokumentacji-do-pzl-p-11-article-2.jpg`)
        .default,
    ],
    pl: {
      title: "Postępy w dokumentacji do PZL P.11",
      summary:
        "W radomskiej firmie DRAFT przygotowywane są rysunki 3D, na podstawie skanów PZL P.11c, wykonanych w Krakowskim Muzeum.",
      text: "W radomskiej firmie DRAFT przygotowywane są rysunki 3D, na podstawie skanów PZL P.11c, wykonanych w Krakowskim Muzeum.",
    },
    en: {
      title: "Progress in documentation of PZL P.11",
      summary:
        "The Radom-based DRAFT company is preparing 3D drawings based on PZL P.11c scans carried out at the Polish Aviation...",
      text: "The Radom-based DRAFT company is preparing 3D drawings based on PZL P.11c scans carried out at the Polish Aviation Museum in Cracow.",
    },
  },
  "kolejne-postepy-w-projekcie-pzl-p-11": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/kolejne-postepy-w-projekcie-pzl-p-11-article-1.jpg`)
        .default,
      require(`../assets/news/kolejne-postepy-w-projekcie-pzl-p-11-article-2.jpg`)
        .default,
    ],
    pl: {
      title: "Kolejne postępy w projekcie PZL P.11",
      summary:
        'Naszym inżynierom udało się rozwiązać problemy z "geometrią" skrzydła Jedenastki.',
      text: 'Naszym inżynierom udało się rozwiązać problemy z "geometrią" skrzydła Jedenastki.',
    },
    en: {
      title: "Further progress in the project",
      summary:
        'Our engineers have managed to solve problems with the "geometry" of the eleventh rate wing.',
      text: 'Our engineers have managed to solve problems with the "geometry" of the eleventh rate wing.',
    },
  },
  "mamy-100-geometrii-pzl-p-11": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/mamy-100-geometrii-pzl-p-11-article-1.jpg`)
        .default,
      require(`../assets/news/mamy-100-geometrii-pzl-p-11-article-2.jpg`)
        .default,
    ],
    pl: {
      title: "Mamy 100 % Geometrii PZL P.11 !",
      summary:
        "Naszym inżynierom udało się uzyskać w 100% prawidłową geomertię samolotu PZL P.11.",
      text: "Naszym inżynierom udało się uzyskać w 100% prawidłową geomertię samolotu PZL P.11.",
    },
    en: {
      title: "We have 100% of the PZL P.11 geometry!",
      summary:
        "Our engineers managed to achieve 100% correct geometry of the PZL P.11 aircraft.",
      text: "Our engineers managed to achieve 100% correct geometry of the PZL P.11 aircraft.",
    },
  },
  "projekt-pzl-p-11-rozpedza-sie": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/projekt-pzl-p-11-rozpedza-sie-article-1.jpg`)
        .default,
    ],
    pl: {
      title: "Projekt PZL P.11 rozpędza się !",
      summary:
        "Nadeszła jesień, sezon lotniczy zakończony, przyszedł czas na zintensyfikowanie działań na polu budowy PZL P.11. Do pomieszczeń hangarowych Fundacji Legendy...",
      text: "Nadeszła jesień, sezon lotniczy zakończony, przyszedł czas na zintensyfikowanie działań na polu budowy PZL P.11. Do pomieszczeń hangarowych Fundacji Legendy Lotnictwa, przyjechała niedawno profesjonalna maszyna do produkcji blachy pokryciowej samolotu PZL P.11. Skończyliśmy poważną część dokumentacji… w tym tygodniu czeka nas wycieczka do szanownego Urzędu Lotnictwa Cywilnego.",
    },
    en: {
      title: "The PZL P.11 project is accelerating!",
      summary:
        "Autumn has come, the aviation season is over, the time has come to intensify activities in the field of PZL...",
      text: "Autumn has come, the aviation season is over, the time has come to intensify activities in the field of PZL P.11 construction. A professional machine for the production of PZL P.11 aircraft cover has recently arrived in the hangars of the The Polish Historical Aircraft Foundation. We have finished a serious part of the documentation ... this week we will have a trip to the Civil Aviation Authority.",
    },
  },
  "prace-nad-dokumentacja-wykonawcza-pzl-p-11": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/prace-nad-dokumentacja-wykonawcza-pzl-p-11-article-1.jpg`)
        .default,
      require(`../assets/news/prace-nad-dokumentacja-wykonawcza-pzl-p-11-article-2.jpg`)
        .default,
      require(`../assets/news/prace-nad-dokumentacja-wykonawcza-pzl-p-11-article-3.jpg`)
        .default,
    ],
    pl: {
      title: "Prace nad dokumentacją wykonawczą PZL P.11",
      summary:
        "W ubiegłym tygodniu oddaliśmy do weryfikacji kompletną dokumentację usterzenia. Weryfikacja przebiegła pomyślnie :)",
      text: "W ubiegłym tygodniu oddaliśmy do weryfikacji kompletną dokumentację usterzenia. Weryfikacja przebiegła pomyślnie :)",
    },
    en: {
      title: "Work on the implementation documentation of PZL P.11",
      summary:
        "Last week we have completed the complete documentation of the tailoring for verification. The verification was successful.",
      text: "Last week we have completed the complete documentation of the tailoring for verification. The verification was successful.",
    },
  },
  "pierwsza-od-1939-r-prawidlowa-geometria-pzl-p-11": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/pierwsza-od-1939-r-prawidlowa-geometria-pzl-p-11-article-1.jpg`)
        .default,
      require(`../assets/news/pierwsza-od-1939-r-prawidlowa-geometria-pzl-p-11-article-2.jpg`)
        .default,
    ],
    pl: {
      title: "Pierwsza od 1939 r. prawidłowa geometria PZL P.11",
      summary:
        "Zespołowi Fundacji Legendy Lotnictwa - inż. Wiesławowi Kosowiczowi pod nadzorem inż. Jerzego Mularczyka udała się nie lada sztuka, mianowicie odtworzono...",
      text: "Zespołowi Fundacji Legendy Lotnictwa - inż. Wiesławowi Kosowiczowi pod nadzorem inż. Jerzego Mularczyka udała się nie lada sztuka, mianowicie odtworzono prawidłową geometrię kadłuba samolotu PZL P.11 – pomimo nie zachowania się żadnej dokumentacji technicznej (dokumentacja oryginalna zaginęła w czasie działań wojennych). Poniżej kilka zdjęć kadłuba PZL P.11",
    },
    en: {
      title: "The first correct geometry PZL P.11 since 1939",
      summary:
        "The Polish Historical Aircraft Foundation Team- engineer Wiesław Kosowicz under the supervision of engineer Jerzy Mularczyk has managed to complete...",
      text: "The Polish Historical Aircraft Foundation Team- engineer Wiesław Kosowicz under the supervision of engineer Jerzy Mularczyk has managed to complete a big task. The correct geometry of the PZL P.11 fuselage was recreated - despite the fact that no technical documentation was preserved (the original documentation was lost during the war). Below are some photos of PZL P.11 hull",
    },
  },
  "prawie-500-stron-dokumentacji-pzl": {
    createdAt: "2017-11-07",
    imageSources: [
      require(`../assets/news/prawie-500-stron-dokumentacji-pzl-article-1.jpg`)
        .default,
    ],
    pl: {
      title: "Prawie 500 stron dokumentacji PZL",
      summary:
        "Właśnie dziś odebraliśmy paczkę z Centralnego Archiwum Wojskowego. Zleciliśmy kwerendę zbiorów pod kontem PZL P.7/11 i co ?... Prawie 500...",
      text: (
        <div>
          <div>
            Właśnie dziś odebraliśmy paczkę z Centralnego Archiwum Wojskowego.
            Zleciliśmy kwerendę zbiorów pod kontem PZL P.7/11 i co ?... Prawie
            500 stron, a wśród nich:
          </div>

          <ul>
            <li>instrukcja naprawy płatowca PZL P.7</li>
            <li>instrukcja wymiany kół samolotu PZL P.11c</li>
            <li>opis płatowca P.7</li>
            <li>opis płatowca P.11A</li>
            <li>200 stron obliczeń statycznych płatowców P.7 i P.11</li>
          </ul>
          <div>
            70 % tych dokumentów od 1939 r. nie oglądało światła dziennego...
            Teraz konstruktorzy zabierają się do dalszej mozolnej pracy...
          </div>
        </div>
      ),
    },
    en: {
      title: "Almost 500 pages of PZL documentation",
      summary:
        "We have just picked up the package from the Central Military Archives. We ordered a collection query under the PZL...",
      text: (
        <div>
          <div>
            We have just picked up the package from the Central Military
            Archives. We ordered a collection query under the PZL P.7 / 11
            account and what? Almost 500 pages, among them:
          </div>
          <ul>
            <li>the PZL P.7 airframe repair manual</li>
            <li>instructions for replacing the PZL P.11c wheels</li>
            <li>description of airframe P.7</li>
            <li>description of airframe P.11A</li>
            <li>200 pages of static airframe calculations P.7 and P.11</li>
          </ul>
          <div>
            70% of these documents did not see the light of day since 1939 ...
            Now the constructors are taking on further work.
          </div>
        </div>
      ),
    },
  },
  "reportaz-w-programie-iii-polskiego-radia": {
    createdAt: "2018-11-09",
    imageSources: [
      require(`../assets/news/reportaz-w-programie-iii-polskiego-radia-article-1.jpg`)
        .default,
    ],
    pl: {
      title: "Reportaż w Programie III Polskiego Radia",
      summary:
        "W dniu 7.11.18 przedstwiciele Fundacji gościli w Programie III Polskiego Radia. Reportaż z ich udziałem jest dostępny do odsłuchania pod...",
      text: (
        <div>
          W dniu 7.11.18 przedstwiciele Fundacji gościli w Programie III
          Polskiego Radia. Reportaż z ich udziałem jest dostępny do odsłuchania
          pod tym linkiem:
          <a
            href="https://www.polskieradio.pl/9/325/Artykul/2212181,Legendarna-jedenastka"
            target="_blank"
            rel="noreferrer"
          >
            https://www.polskieradio.pl/9/325/Artykul/2212181,Legendarna-jedenastka
          </a>
          .
        </div>
      ),
    },
    en: {
      title: "Radio broadcast in Program III of the Polish Radio",
      summary:
        "On November 7, 188, representatives of the Foundation were present in the Program III of Polish Radio. Radio broadcast with their...",
      text: (
        <div>
          On November 7, 188, representatives of the Foundation were present in
          the Program III of Polish Radio. Radio broadcast with their
          participation is available at this link:
          <a
            href="https://www.polskieradio.pl/9/325/Artykul/2212181,Legendarna-jedenastka"
            target="_blank"
            rel="noreferrer"
          >
            https://www.polskieradio.pl/9/325/Artykul/2212181,Legendarna-jedenastka
          </a>
          .
        </div>
      ),
    },
  },
  "kolejna-praca-magisterska-z-p11-stki-obroniona": {
    createdAt: "2019-01-25",
    pl: {
      title:
        "Kolejna praca magisterska z P11-stki obroniona. Gratulujemy Pawłowi Stalmachowi bardzo ciekawej pracy!",
      summary:
        "Animacja opływu bazowego profilu P.11c (profil nr.323 Instytutu Aerodynamicznego w Warszawie). Animacja opływu profilu Bartel 37 II a...",
    },
    en: {
      title:
        "Another master's thesis on P11 defended. Congratulations to Paweł Stalmach on his very interesting work!",
      summary:
        "Animation of a flow over the P.11c basics airfoil (airfoil No.323 of Warsaw Aerodynamics Istitiute). Animation of...",
    },
  },
  "analiza-sil-oporu-aerodynamicznego-poszczegolnych-podzespolow-samolotu-pzl-p-11c":
    {
      createdAt: "2020-06-13",
      pl: {
        title:
          "ANALIZA SIŁ OPORU AERODYNAMICZNEGO POSZCZEGÓLNYCH PODZESPOŁÓW SAMOLOTU PZL P.11c",
        summary:
          "Pierwsza połowa XX wieku to okres bardzo dynamicznego rozwoju lotnictwa. Patrząc na ewolucję samolotów z tego okresu bardzo łatwo zauważyć...",
      },
    },
  "badin-historia-niezwykla": {
    createdAt: "2021-12-28",
    pl: {
      title: "Niezwykła historia pozyskania kontrolera lotu BADIN",
      summary:
        "W ubiegłym roku nawiązaliśmy kontakt z pasjonatem historycznych samolotów Panem Stefanem Wilskim. Pan Stefan jest Belgiem polskiego pochodzenia , działa w prywatnym muzeum lotnictwa w Brukseli – BAPA...",
    },
    en: {
      title:
        "The amazing story of the acquisition of the BADIN flight controller",
      summary:
        "Last year, we established contact with the enthusiast of historical aircraft, Mr. Stefan Wilski.  Mr. Stefan is a Belgian of Polish origin, he works in a private aviation museum in Brussels - BAPA",
    },
  },
};
