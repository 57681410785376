import ProgressBar from "react-bootstrap/ProgressBar";

function EnContent() {
  return (
    <div>
      <h3 className="mt-5">(1/6) Preliminary project</h3>
      <ProgressBar now={100} label={`${100}%`} />
      <div className="initial-project">
        <ol>
          <li>Geometries</li>
          <li>Completion of equipment</li>
          <li>Truck analysis of the airplane</li>
          <li>Aerodynamic project of the airplane</li>
          <li>Secondary loads</li>
        </ol>
      </div>
      <h3 className="mt-5">(2/6) Technical project</h3>
      <ProgressBar now={80} label={`${80}%`} />
      <div className="technical-project">
        <ol>
          <li>Construction documentation</li>
          <li>Calculation documentation</li>
        </ol>
      </div>
      <h3 className="mt-5">(3/6) Production of the replica</h3>
      <ProgressBar now={10} label={`${10}%`} />
      <div className="rejoinder-production">
        <ol>
          <li>
            Gathering the necessary amount of materials in the right assortment
            to produce the components of the aircraft structure and the
            necessary production equipment
          </li>
          <li>Production of the required amount of fine-grained dural sheet</li>
          <li>
            Production of tooling:
            <ul>
              <li>detail molds</li>
              <li>
                mounting devices: hull (shell part and both trusses), chassis,
                wings, horizontal tail, vertical tail, engine bed, exhaust
                manifold, Townend ring
              </li>
            </ul>
          </li>
          <li>Creating parts and assemblies of the aircraft structure</li>
          <li>The assembly of the plane, equipping it and painting</li>
          <li>
            Notification of completion of the construction and readiness of the
            aircraft for flight tests
          </li>
        </ol>
      </div>
      <h3 className="mt-5">(4/6) External loads of the aircraft</h3>
      <ProgressBar now={0} label={`${0}%`} />
      <h3 className="mt-5">(5/6) Preparation for the flight</h3>
      <ProgressBar now={0} label={`${0}%`} />
      <div className="flight-preparation">
        <ol>
          <li>Development of programs (agreed with ULC - KCSP)</li>
          <li>Conduct of static tests</li>
          <li>Analysis of areo-elasticity properties</li>
          <li>Ground trials</li>
          <li>Developing an accompanying documentation</li>
          <li>Flight tests</li>
        </ol>
      </div>
      <h3 className="mt-5">(6/6) After completing flight tests</h3>
      <ProgressBar now={0} label={`${0}%`} />
      <div className="after-successful-flight">
        <ol>
          <li>Publishing a flight test report</li>
          <li>Release of the full use instructions</li>
          <li>
            Release of the instructions for providing flight airworthiness
          </li>
        </ol>
      </div>
      <h3 className="mt-5">Final remarks</h3>
      <p>
        The airplane will be built in the "SPECIAL" category in sub-category S5
        on the basis of: RULES FOR ALLOWING AIR FLIGHTS OF A CATEGORY "SPECIAL"
        AND GENERAL PROCEDURE FOR ISSUING A PERMISSION TO EXERCISE FLIGHTS IN A
        "SPECIAL" CATEGORY. Introduced by Decision Nr 4 GILC from the 22 III
        2001.
      </p>
    </div>
  );
}

export default EnContent;
